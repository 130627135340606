<template>
    <BaseScene 
        name="stage-closed-curtain" 
        :background="ResourceManager.Get('scenes.stageclosedcurtain')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene"
    >
        <SceneTrigger scene="stage-guest-view" x="0" y="5" :sound="ResourceManager.Get('shorts.curtainopening')" :delay="500"/>

        <div class="sprite" :class="{off: !bgm.playing()}"></div>
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import ResourceManager from "@/classes/resource-manager";
import { Howl } from 'howler';

export default {
    components: {
        BaseScene,
        SceneTrigger
    },
    mounted() {

    },
    data() {
        return {
            ResourceManager,
            bgm: null,
            bgmLaunch: null
        };
    },
    methods: {
        OnEnterScene() {
            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.welcome')],
                    loop: false,
                    volume: 1
                });
            }

            this.bgmLaunch = setTimeout(() => {
                if (!this.bgm.playing()) {
                    this.bgm.play();
                }
            }, 2000);
    
        },
        OnLeaveScene() {
            if (this.bgmLaunch) {
                clearTimeout(this.bgmLaunch);
                this.bgmLaunch = null;
            }


            if (this.bgm.playing()) {
                this.bgm.stop();
            }
        }
    }      
}
</script>

<style lang="scss">
.scene-stage-closed-curtain {
    .sprite {
        background-image: url('../../assets/images/announcer-sprite.png');
        background-size: auto 100%;
        position: absolute;        
        @include width-ar(16.407);
        @include height-ar(40.1043);
        // width: 315px;
        // height: 770px;
        will-change: background-position;
        user-select: none;
        
        animation-name: sprite-cycle-1;
        animation-timing-function: steps(8);
        animation-iteration-count: infinite;
        animation-duration: 2s;
        animation-delay: 0s;		                
        @include xy-ar(20, 4);
        transform: scale(0.7) translate(-50%, -50%);
        left: 50%;
        top: 50%;

        &.off {
            animation-name: none;
        }
    }

    @keyframes sprite-cycle-1 {
        100% {
            //background-position: -131.256vw 0;
            @include sprite-target-ar(-131.256);
        }        
    }     
}
</style>